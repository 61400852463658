@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes WelcomePageGradient2 {
  0%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@keyframes WelcomePageGradient2 {
  0%{background-position:100% 54%}
  100%{background-position:0% 47%}
}


.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

img {
  
  width: 100%;
}

img.logo-header {
  width: 300px;
  position: relative;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
}



.logo-layer-1 {
  position: absolute;
  top: 15%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  width: 40%;
  height: auto;
}

.logo-layer-2 {
  position: absolute;
  top: 15%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
  background-size: 200% 180%;

  -webkit-animation: WelcomePageGradient2 4s ease ;
  animation: WelcomePageGradient2 4s ease ;
  z-index: 9;
  width: 40%;
  height: auto;
}

.main {
  background-color: #000;
  width: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}


.main-body {
  position: fixed;
  top: 0;
  background-color: black;
  background-color: #0e0205;
  background-color: black;
  width: 100%;
  left: 0;
  margin: 0;
  padding-top: 200px;
  text-align: center;
}

.main-page {
  position: relative;
  background-color: black;
  top: 50px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding-bottom: 80px;
  min-height: 100vh;

}

.landing-page {
  background-color: black;
  top: 0;
  position: relative;
}

.entry-text-o {
  position: relative;
  font-family: proxima-nova, sans-serif;
  font-size: 1.4em;
  z-index: 1000;
  font-weight: lighter;
  line-height: 1.1;
  font-size: 28px;
  letter-spacing: -0.5px;
  padding-top: 30px;
  margin-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: radial-gradient(ellipse at center, #faf1fd 10%, #000 100%);
  background-size: 100% 200%;
  transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
  background-position: center center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
}

.entry-text-p {
  position: relative;
  font-family: proxima-nova, sans-serif;

  z-index: 1000;
  font-weight: lighter;
  line-height: 1.1;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: radial-gradient(ellipse at center, #ffffff 10%, #6b6b6b 100%);
  background-size: 100% 200%;
  transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
  background-position: center center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
}

.down-60 {
  position: relative;

  width: 100%;

  font-size: 28pt;
}



.button-background {
  position: absolute;
  display: block;
  width: 188px;
  left: 51%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  top: 24vh;
  height: 54px;
  margin: 20px auto;
  vertical-align: middle;
  font-size: 45px;
  color: white;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-appearance: none;
  transition: .4s;
  border-image: linear-gradient(to right, #005e7f, #61b6cd);
  background: linear-gradient(to right, #7b4397, #e72f60);
  background: black;
  border-radius: 50px;
  background-size: 100%;
  background: rgb(0, 0, 0);
  border: 2px transparent;
  border-radius: 30px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.button-background::after {
  z-index: 1;
  border-radius: 50px;
  content: '';
  display: block;    
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2.5px);
  height: calc(100% + 2px);
  background: linear-gradient(#61b6cd 35%, #005e7f 45%);
  background: linear-gradient(to right, #7b4397, #e72f60);
  background-size: 100% 200%;
  background-position: 0 var(--modal-button-gradient-pos);
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
}

.moving-gradient {
  background: linear-gradient(306deg, #7b4397, #e72f60);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
}

.get-started2 {
  position: absolute;
  display: block;
  top: 24vh;
  left: 50.5%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 185px;
  height: 48px;
  margin: 20px auto;
  vertical-align: middle;
  font-size: 45px;
  color: white;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-appearance: none;
  transition: .4s;
  border-image: linear-gradient(to right, #005e7f, #61b6cd);
  background: linear-gradient(to right, #7b4397, #e72f60);
  border-radius: 50px;
  background-size: 100%;
  border: 2px transparent;
  background: black;
  background-color: black;
  border-radius: 30px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  
}

.get-started2::after {
  z-index: -1;
  border-radius: 50px;
  content: '';
  display: block;    
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2.5px);
  height: calc(100% + 2px);
  background: linear-gradient(#61b6cd 35%, #005e7f 45%);
  background: linear-gradient(to right, #7b4397, #e72f60);
  background-color: black;
  background: black;
  background-size: 100% 200%;
  background-position: 0 var(--modal-button-gradient-pos);
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  -webkit-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
}


/* Login Form */
.login-form-container {
  margin-top: 40px;
  text-align: center;
  background-color: black;
}

.login-form {
  margin: 0 auto;
}

.login-form input {
  outline: none;
  background-color: #272727;
  width: 90%;
  padding: 10px;
  margin-top: 0px;
  border: none;
  border-bottom: 1px solid transparent;
  box-shadow: none;
  margin-bottom: 10px;
  font-size: 16px;
}

input:focus {
  color: white;
  border: 1px solid #e72f60;
  font-weight: 500;
  font-size: 16px;
}

.tweets {
margin-top: 50px;
position: absolute;
}

.login-title {
font-size: 11pt;
text-transform: uppercase;
}



/* Dashboard */
.dashboard {
background-color: white;
margin: 0px;
margin-top: -10px;
}

/* LOGIN FORM */

/* LOGIN FORM */

.nav-btn {
color: white;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 450;
text-align: left;
text-transform: uppercase;
padding: 20px;
padding-top: 12px;
font-size: 14px;
position: fixed;
font-weight: 300;
letter-spacing: -0.5px;
left: 0;
top: 0;
width: 40%;
z-index: 9999999999999999999999;
background-color: transparent;
}

.nav-btn i {
font-size: 14px;
font-weight: 300;
padding-right: 5px;
}

.black {
color: #0F0F0F;
}


.blk {
  color: #0F0F0F;
  }

.login-btn {
position: fixed;
right: 0px;
top: 12px;
text-align: right;
color: white;
font-size: 9pt;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 450;
text-transform: uppercase;
padding-left: 15px; padding-right: 15px;
z-index: 999999999999;
width: 40%;
height: 50px;
background-color: transparent;
}

.overlay {
position: fixed;
top: 0;
height: 100vh;
width: 100%;
background-color: rgba(0, 0, 0, 0.5);
}
.ring {
position: absolute;
top: 50%; left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
background-color: transparent;
}

.login-btn i{
font-size: 16pt;
padding: 4px;
color: rgba(128, 128, 128, 0.733);
}

h4 {
color: #f5f5f7;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 23px;
font-weight: lighter;
padding-bottom: 30px;
}

h3 {
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
color: #f5f5f7;
font-weight: 500;
font-size: 1.8em;
text-align: left;
line-height: 1.2em;
padding-bottom: 20px;
text-transform: uppercase;
}

.login-form {
display: block;
position: relative;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
}

label {
display: block;
color: rgba(255, 255, 255, 0.685);
text-align: left;
width: 80%;
margin: 5px auto;
margin-left: 6.5%;
text-transform: uppercase;
font-size: 9pt;
font-weight: 500;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input {
  display: block;
  top: 0px;
  position: relative;
  width: 82%;
  color: white;
  background-color: #161618;
  background-color: #1c1c1e;
  background-color: #272727;
  margin: 0 auto;
  border: none;
  height: 28px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 8px;
  padding-left: 15px;
  border-radius: 0px;
  border-bottom: 1px solid transparent;
  line-height: 1.2em;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
  background: rgb(18, 18, 18);
  color: white;

}

.error {
  display: block;
  padding: 5px;
  color: #e72f60;
  font-size: 9pt;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  text-align: center;
}

textarea {
  display: block;
  background-color: #161618;
  background-color: #272727;
  position: relative;
  color: white;
  top: 0px;
  height: 100px;
  width: 82%;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-radius: 0px;
  margin: 0 auto;
  margin-bottom: 10px;
  border: none;
}


textarea::-webkit-input-placeholder {
  color: #73737a;
  font-weight: 500;
  font-size: 16px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


textarea:-ms-input-placeholder {
  color: #73737a;
  font-weight: 500;
  font-size: 16px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


textarea::placeholder {
  color: #73737a;
  font-weight: 500;
  font-size: 16px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

textarea:focus {
  outline: none;
  border: none;
  background-color: #272727;
}

input::-webkit-input-placeholder {
  color: #73737a;
  font-weight: 500;
}

input:-ms-input-placeholder {
  color: #73737a;
  font-weight: 500;
}

input::placeholder {
  color: #73737a;
  font-weight: 500;
}


input:focus {
  border: none;
  outline: none;
  border: 1px solid #e72f60;
  border-radius: 0px;
  background-color: #161618;
  background-color: #272727;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input:after {
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(29, 29, 29);
  background-color: rgba(29, 29, 29, 0.747);
}

input.submit {
  position: relative;
  display: block;
  color: white;
  top: 20px;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-appearance: none;
  transition: .4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 85%;
  border: 0px solid transparent;
  background-clip: border-box;
  background-image: linear-gradient(to left, #7b4397, #e72f60);
}

input:-webkit-autofill {
  -webkit-text-fill-color: #161618f1 !important;
}

input.next {
  position: relative;
  color: white;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-appearance: none;
  transition: .4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 90%;
  border-radius: 50px;
  border: 0px solid transparent;
  background-clip: border-box;
  background-image: linear-gradient(to left, #7b4397, #e72f60);
}

input.inside5 {
  outline: 1px solid #e72f60;
  color: white;
  font-size: 10.5pt;
  line-height: 1.3;
  font-weight: 550;
  margin: 0;
  background-color: transparent;
  width: 70px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input.next-no {
  display: block;
  position: relative;
  color: white;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-appearance: none;
  transition: .4s;
  width: 90%;
  border-radius: 50px;
  border: 0px solid transparent;
  background-clip: padding-box;
  background: linear-gradient(to top right, #f17C35, #e72f60);
  margin-top: 5px;
  top: 5px;
  -webkit-filter: blur(3px);
          filter: blur(3px)
}

input.submit-no {
  display: block;
  position: relative;
  color: white;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-appearance: none;
  transition: .4s;
  width: 85%;
  border: 0px solid transparent;
  background-clip: padding-box;
  background-image: linear-gradient(to left, #7b4397, #e72f60);
  margin-top: 5px;
  top: 20px;
  -webkit-filter: blur(3px);
          filter: blur(3px);
  z-index: 100;
}

input[type="file"] {
  display: none;
}

input[type="file"] i{
  color: #b3b3b3;
  text-align: center;
}

.add-input {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.add-input i {
  color: #b3b3b3;
  position: absolute;
  font-size: 12pt;
  top: 50%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.custom-file-upload-edit {
position: absolute;
margin-top: 0;
padding-left: 0;
background-color: #3333339d;
color: #e72f60;
width: 88%;
height: 105px;
z-index: 9999;
}

.custom-file-upload-edit i {
color: white;
font-size: 14pt;
padding: 42px;
}

.ring {
position: absolute;
top: 50%; left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
}

.disclaimer {
  display: block;
  position: relative;
  color: #f5f5f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  width: 90%;
  font-size: 12px;
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0 auto;
  line-height: 1.6em;
  margin-top: 20px;
  padding-bottom: 0px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
}

.connecting {
  display: none;
  z-index: 2000000000;
  color: #cd9c11;
}

.entry-image-holder {
  position: relative;
  height: 100%;
}
/* LOGIN FORM */

.login-box {
  width: 60%;
  margin: 0 auto;
}

.full-body {
  z-index: 99999;
  background-color: rgb(18, 18, 18);
  background-color: black;
  height: 100vh;
  overflow-y: scroll;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  padding-top: 50px;
  width: 100%;
  color: white;
  text-align: center;
}

.hidden {
  display: none;
  transition: .4s;
}

.appointment-box {
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: left;
  background-color:  #140512;
  background-color: #111011;
  padding: 7px;
  padding-bottom: 12px;

}


.appointment-box::after {
  width: 92%;
  background-clip: border-box;
  background: linear-gradient(306deg, #7b4397, #e72f60);
  background-size: 400% 400%;
  background-clip: border-box;
  color: transparent;
  -webkit-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
}



.today-time {
  font-weight: 700;
  color: rgb(240, 240, 240);
  padding-top: 7px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 10pt;
  display: inline-block;
}

.today-type {
  padding-top: 5px; padding-bottom: 5px;
  color: rgba(156, 156, 156, 0.89);
  text-transform: uppercase;
  font-size: 10pt;
  margin-top: 0px;
}

.today-due {
  display: block;
  margin-top: 7px;
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.89);
  text-transform: uppercase;
  font-size: 8pt;
  background-color: transparent;
  border: 1px solid #666666;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
}

.today-name {
  padding-top: 5px; padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.89);
  text-transform: uppercase;
  font-size: 10pt;
}

.today-notes {
  color: rgb(102, 102, 102);
  font-style: italic;
  font-size: 10pt;
  padding-top: 5px; padding-bottom: 5px;
}

.scroll {
  overflow-y: scroll;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}


.course-sections {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(255, 255, 255, 0.959);
  width: 60%;
  padding-bottom: 100px;
  margin: 0 auto;
}

span.plus {
  color: black;
  position: relative;
  float: right;
  right: 24px;
  font-size: 14px;
  vertical-align: top;
  margin-top: -4.5%;
 
}


@media (max-width: 800px) {

  span.plus {
    color: black;
    position: relative;
    float: right;
    right: 20px;
    top: 0;
    font-size: 14px;
    vertical-align: top;
    margin-top: -5%;
    padding-top: unset;
  }


.course-sections {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(255, 255, 255, 0.959);
  width: 96%;
  margin: 0 auto;
  padding-bottom: 50px;
}
  .down-60 {
    position: relative;
    padding-top: 0;
    font-size: 20pt;
  }

  .login-box {
    width: 100%;
    margin: 0 auto;
}

  .logo-layer-1 {
    position: absolute;
    top: -20%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1;
    width: 92%;
    height: auto;
  }
  
  .logo-layer-2 {
    position: absolute;
    top: -20%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
    background-size: 200% 180%;
  
    -webkit-animation: WelcomePageGradient2 6s ease ;
    animation: WelcomePageGradient2 6s ease ;
    z-index: 9;
    width: 92%;
    height: auto;
  }
}

.wht {
  color: white;
}


.course-section-title {
text-transform: uppercase;
font-size: 12pt;
font-weight: lighter;
letter-spacing: -1px;
padding: 15px;
opacity: 1;
color: #ffffff;
font-family: 'Roboto Condensed', sans-serif;
border-top: 1px solid rgba(255, 255, 255, 0.959);
border-bottom: 1px solid rgba(255, 255, 255, 0.959);
}

.course-section-title:hover {
  cursor: pointer;
}

.course-body {
  text-align: justify;
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: -0.5px;
  padding-bottom: 20px;
  font-size: 12pt;
  line-height: 1.4em;
  padding-top: 20px;
  transition: .6s;
}

.width-90 {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
}



.course-reading {
  font-weight: 300;
  letter-spacing: -0.5px;
  font-style: italic;
}

.no-abs {
  position: relative;
  text-align: center;
}

h1.header {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12pt;
  color: #ffffff;
}

.assignment {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  border-top: 1px solid black;
}

.assignment-title {
  
    background: linear-gradient(to left, #7b4397, #e72f60);
    
    -webkit-background-clip: text;
    color: black;
    font-weight: 300;
    letter-spacing: .007em;
    font-size: 10pt;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: -0.2px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    vertical-align: top;
}

.assignment-desc {
  text-align: left;
  width: 100%;
  padding: 0;
  margin: 0;
}



.assignment-desc {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  padding: 20px;
  width: 90%;
  padding-left: 20px;
}

a {
  text-decoration: none;
  color: #2069e0;
  font-weight: 350;
  font-size: 10pt;

}

.assignment {
  width: 90%;
  margin: 0 auto;
  font-size: 11pt;
}

a.assignment {
  display: block;
  padding-left: 0px;
  background-color: transparent;
  border-top: none;
  width: 100%;
  font-size: 10pt;
  font-weight: 350;
}

* {
  transition: .6s;
}

img {
  margin-top: 20px;
  background-color: white;
}

img.logo-header {
  background-color: transparent;
}

img.main-img {
  background-color: transparent;
}

.c {
  text-align: center;
  border-top: none;
  border-bottom: none;
}

.hamburger {
  position: absolute;
  right: 10px;
  top: 0px;
  color: white;
  height: 50px;
  width: 20%;
  font-size: 18pt;
}

.hamburger a {
  display: block;
  position: absolute;
  top: 14px;
  right: 12px;
  font-size: 12pt;
  font-weight: 550;
  text-transform: uppercase;
  z-index: 99;
  color: white;
}

.profile {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: WHITE;
  color: black;
}

.blk {
  color: #0F0F0F;
  font-size: 14px;
  text-align: left;
  margin: 0; padding-left: 0;
  }
